<template>
  <v-main>
    <div class="error text-center white--text title" v-if="serverError">{{ serverError }}</div>
    <v-container class="fill-height" fluid>
      <v-row justify="center" align="center">
        <v-col cols="3">
          <v-card>
            <v-card-title class="info white--text title justify-center primary">
              Восстановление пароля
            </v-card-title>
            <v-card-text>
              <v-form v-model="dataValid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      type="password"
                      v-model="passwordResetForm.password"
                      label="Новый пароль"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      type="password"
                      v-model="passwordResetForm.passwordRepeat"
                      label="Повторите пароль"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="info" to="/login">Отмена</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="passwordReset" :loading="loading" :disabled="loading||!dataValid">Далее</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import rules from '@/libs/validate-rules.js'
export default {
  data: () => ({
    passwordResetForm: {
      password: '',
      passwordRepeat: '',
    },
    dataValid: false,
    rules: rules,
    loading: false,
    serverError: ''
  }),
  methods: {
    passwordReset() {
      this.loading = true
      this.$axios.post('user/account/password-reset', { ...this.passwordResetForm, token: this.$route.query.token }).then(res => {
        this.$router.push('/login')
      }).catch(err => {
        if(err.response.data.error) {
          this.serverError = err.response.data.error
        } else {
          this.serverError = 'Не удалось соединиться с сервером'
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
